var blnWeatherLoaded = false;

jQuery(document).ready(function($) {
	var $loading = $("#weather-loading");
	var $content = $("#weather-content");

	function refreshWeather() {
		$.ajax({
			url: "/api/weather/data",
			dataType: "html",
			success: function(data, textStatus, jqXHR) {
				$content.html(data);

				blnWeatherLoaded = true;
			},
			error: function(jqXHR, textStatus, errorThrown) {
				$content.html("Weather Unavailable");
			},
			complete: function() {
				$loading.hide();
				$content.show();
			}
		});
	}

	if (!blnWeatherLoaded) {
		refreshWeather();
	}
});
