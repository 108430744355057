jQuery(document).ready(function($) {
	function loadExchangeRates() {
		$.ajax({
			url: "/api/exchangerates/data",
			dataType: "json",
			success: function(data, textStatus, jqXHR) {
				var exchangeRatesDropDown = $( '#rate' );

				$.each(data, function(key, value) {
					exchangeRatesDropDown.prepend('<option value="' + key + '">EUR/' + key +':' + value + '</option>');
				});

				exchangeRatesDropDown.val('USD');
			}
		});
	}

	loadExchangeRates();

	$("#rate").change( function( event ) {
		event.preventDefault();
		var url = $(this).find(":selected").data("url");
		if ( url !== undefined ) {
			window.location.href = url;
		}
	});
});
